import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout";
import SEO from "../components/seo";

const CookiePolicyPage = () => (
	<Layout>
		<SEO title="Cookie Policy" />
		<div className="container p-4 text-brand-grayt">
			<h1 className="text-3xl uppercase mb-8">
				<Trans>Cookie Policy</Trans>
			</h1>
			<div className="mb-8">
				<Trans i18nKey="Access_to_dgital_site">
					Access to DGITAL site may involve the use of cookies, although the website can run without the use
					of these. Cookies are small files of information stored on the browser of each user so that the
					server can recognise certain information that only the server can read. Cookies last for a limited
					time. No cookies can permit any telephone, email or other identification details to be revealed.
					Cookies cannot extract any information from the user's hard drive or steal personal information. The
					only way that a user's private information can form part of a cookie file is if the user personally
					give this information to the server.
				</Trans>
			</div>
			<div>
				<Trans i18nKey="Although_cookies">
					Although cookies help DGITAL to optimise service supply, users who do not wish to receive cookies or
					wish to be informed of their presence, can configure their browser to that effect.
				</Trans>
			</div>
		</div>
	</Layout>
);

export default CookiePolicyPage;
